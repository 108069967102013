import React from "react"

const IndexPage = () => (
  <>
    <h1>
      Welcome to <b>Gatsby!</b>
    </h1>
    <section>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <div className="page__body">
              <form
                action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                method="POST"
              >
                <input type="hidden" name="oid" value="00D2w000008qpI9" />
                <input
                  type="hidden"
                  name="retURL"
                  value="http://www.nswmealsonwheels.org.au"
                />

                <label htmlFor="first_name">First Name</label>
                <input
                  id="first_name"
                  maxLength="40"
                  name="first_name"
                  size="20"
                  type="text"
                />
                <br />

                <label htmlFor="last_name">Last Name</label>
                <input
                  id="last_name"
                  maxLength="80"
                  name="last_name"
                  size="20"
                  type="text"
                />
                <br />

                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  maxLength="80"
                  name="email"
                  size="20"
                  type="text"
                />
                <br />

                <label htmlFor="company">Company</label>
                <input
                  id="company"
                  maxLength="40"
                  name="company"
                  size="20"
                  type="text"
                />
                <br />

                <label htmlFor="city">City</label>
                <input
                  id="city"
                  maxLength="40"
                  name="city"
                  size="20"
                  type="text"
                />
                <br />

                <label htmlFor="state">State/Province</label>
                <input
                  id="state"
                  maxLength="20"
                  name="state"
                  size="20"
                  type="text"
                />
                <br />

                <input type="submit" name="submit" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default IndexPage
